var accordions = document.querySelectorAll('.accordion');
var accordionItem = '.accordion__item';
var accordionActiveClass = 'accordion__item--active';

for(let accordion of accordions)
{
    let accordionTitles = accordion.querySelectorAll('.accordion__title');

    //Loop through accordion titles within this accordion
    for(let accordionTitle of accordionTitles)
    {
        accordionTitle.addEventListener('click', event =>
        {
            //If this accordion item contains the active class, close the item...else open it
            if(event.currentTarget.closest(accordionItem).classList.contains(accordionActiveClass))
            {
                closeAccordionItem(event);
            }
            else
            {
                openAccordionItem(event);
            }
        });
    }
}

/*
Opens an accordion item by adding the active class

@param {Object} element - the accordion element
*/
function openAccordionItem(element)
{
    element.currentTarget.closest(accordionItem).classList.add(accordionActiveClass);
    element.currentTarget.setAttribute('aria-expanded', true);
    element.currentTarget.nextElementSibling.setAttribute('aria-hidden', false);
}

/*
Closes an accordion item by removing the active class

@param {Object} element - the accordion element
*/
function closeAccordionItem(element)
{
    element.currentTarget.closest(accordionItem).classList.remove(accordionActiveClass);
    element.currentTarget.setAttribute('aria-expanded', false);
    element.currentTarget.nextElementSibling.setAttribute('aria-hidden', true);
}


// =======================================================================
// Carousel
// =======================================================================

var heroCarousel = ".image-text-overlay__image .image-text-overlay__image__inner";

if($(heroCarousel).length > 0)
{
    $(heroCarousel).each(function()
    {
        $(this).slick({
            dots: false,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 500
            //appendDots: $(this).next('.hero__pagination')
        });
    });
}




// =======================================================================
// Google Maps
// =======================================================================

var GoogleMap =
{
    icon: null,
    map: null,
    markersArray: [],
    i: null,
    loadSingleMarkerMap: function(latitude, longitude, mapID)
    {
        var mapID = mapID ? mapID : '',
            lat = (latitude ? latitude : 54.309313),
            lng = (longitude ? longitude : -2.548828);

        GoogleMap.map = new google.maps.Map(document.getElementById(mapID), {
            zoom: 15,
            center: new google.maps.LatLng(lat, lng),
            styles: [	{		"featureType":"landscape",		"stylers":[			{				"hue":"#FFBB00"			},			{				"saturation":43.400000000000006			},			{				"lightness":37.599999999999994			},			{				"gamma":1			}		]	},	{		"featureType":"road.highway",		"stylers":[			{				"hue":"#FFC200"			},			{				"saturation":-61.8			},			{				"lightness":45.599999999999994			},			{				"gamma":1			}		]	},	{		"featureType":"road.arterial",		"stylers":[			{				"hue":"#FF0300"			},			{				"saturation":-100			},			{				"lightness":51.19999999999999			},			{				"gamma":1			}		]	},	{		"featureType":"road.local",		"stylers":[			{				"hue":"#FF0300"			},			{				"saturation":-100			},			{				"lightness":52			},			{				"gamma":1			}		]	},	{		"featureType":"water",		"stylers":[			{				"hue":"#0078FF"			},			{				"saturation":-13.200000000000003			},			{				"lightness":2.4000000000000057			},			{				"gamma":1			}		]	},	{		"featureType":"poi",		"stylers":[			{				"hue":"#00FF6A"			},			{				"saturation":-1.0989010989011234			},			{				"lightness":11.200000000000017			},			{				"gamma":1			}		]	}]
        });

        GoogleMap.icon = {
            anchor: new google.maps.Point(15, 42),
            scaledSize: new google.maps.Size(30, 42),
            size: new google.maps.Size(30, 42),
            url: '/wp-content/themes/redlion/images/svgs/map-marker.svg'
        };

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng),
            icon: GoogleMap.icon,
            animation: google.maps.Animation.DROP,
            map: GoogleMap.map
        });
    }
};

//Display single marker Google map
var singleMarkerGoogleMap = $('.google-map.single-marker');

if(singleMarkerGoogleMap.length)
{
    $(singleMarkerGoogleMap).each(function()
    {
        var id = $(this).attr('id'),
            lat = $(this).attr('data-lat'),
            lng = $(this).attr('data-lng');

        if(lat && lng)
        {
            GoogleMap.loadSingleMarkerMap(lat, lng, id);
        }
    });
}




// =======================================================================
// Header Menu
// =======================================================================

var Menu =
{
    mainMenuBurger: $('.header__nav-burger'),
    mainMenuNavItem: $('.header__menu__nav li a'),
    mainMenuClose: $('.header__nav-close'),
    showHideDesktopMenu: function()
    {
        $('html').toggleClass('header-mobile-nav-active');
    }
};

Menu.mainMenuNavItem.click(function()
{
    Menu.showHideDesktopMenu();
});

Menu.mainMenuBurger.click(function()
{
    Menu.showHideDesktopMenu();
});